import { InitConfiguration } from 'model/init-config';
import { config as baseConfig } from '../../../../legacy/customer/base/allmoeb/base-init.conf';

const defaultConfig: InitConfiguration = {
	override: {
		'helper/uploadProgressHelper': 'customer/helper/uploadProgressHelper',
		'plugins/lightbox/jquery.lightbox': 'customer/plugins/jquery.lightbox',
	},
};

export const config: InitConfiguration = {
	override: { ...defaultConfig.override, ...baseConfig.override },
};
